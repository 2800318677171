// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    keycloakUrl: 'https://keycloak.gdlabdev.labsdemo.es/auth/',
    keycloakRealm: 'alfresco',
    keycloakClientId: 'gdlab-frontend',
    servolabUrl: 'https://servolabws.gdlabdev.labsdemo.es/',
    baseUrl: 'https://backend.gdlabdev.labsdemo.es/',
    policyAlfrescoId: 'cb80e52b-fe24-4af2-8557-a68b9200191b',
    alfrescoBaseUrl: 'https://alfresco.gdlabdev.labsdemo.es',
};
